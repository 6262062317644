.input {
  width: 100%;

  padding: 10px;

  font-size: 16px;

  border: 1px solid $lightGray;
  border-radius: 5px;

  outline: none;

  background-color: $cream;

  transition: all .2s ease;

  cursor: pointer;

  &.invalid {
    border-color: $red;
  }

  &-wrapper {
    width: 350px;

    margin: 10px 0;
  }

  @media screen and (max-width: 1300px) {
    &-wrapper {
      margin: 30px 0;
    }
  }
}

.select {
  width: 100%;
  
  padding: 10px;

  font-size: 16px;

  border: 1px solid $lightGray;
  border-radius: 5px;

  outline: none;

  background-color: $cream;

  transition: all .2s ease;

  cursor: pointer;

  &.invalid {
    border-color: $red;
  }

  &-wrapper {

    &--default {
      width: 350px;
    }
  }
}

.css-2b097c-container {
  width: 350px;
}


.textarea {
  width: 100%;
  min-height: 100px;

  padding: 10px;

  font-size: 16px;

  resize: vertical;

  border: 1px solid $lightGray;
  border-radius: 5px;

  outline: none;

  background-color: $cream;

  transition: all .2s ease;

  cursor: pointer;

  &.invalid {
    border-color: $red;
  }

  &-wrapper {
    width: 350px;

    margin: 10px 0;
  }
}
