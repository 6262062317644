.auth {
  &-wrapper {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    background-image: linear-gradient(to right top, #274d3e, #2a754b, #429d4b, #6dc53e, #a8eb12);
  }

  &-card {
    max-width: 350px;
    width: 100%;

    border-radius: 10px;
    box-shadow: 10px 10px 39px -9px rgba(0,0,0,0.5);
    
    &-container {
      padding: 30px;
      
      border-radius: 10px 10px 0 0;
      
      background-color: $cream;

      & input { 
        max-width: 290px !important;
      }
    }
  }
}