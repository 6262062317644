.errors {

  &-wrapper {
    width: 100%;
    height: 100vh;

    @include flex();

    background-color: $lightGray;
  }

  &-container {
    padding: 30px;

    text-align: center;

    border-radius: 5px;

    background-color: $cream;

    & button {
      margin: 30px auto 0 !important;
    }
  }
}