.btn {
  border: 0;
  outline: none;

  background-color: $darkGreen;

  transition: all .2s ease;

  cursor: pointer;

  &-popover {
    margin-top: 60px;
    margin-left: 100px;
    height: 25px;
    width: 25px;
  }

  &--lang {
    margin: 10px 10px 10px 0;
    padding: 5px 25px;

    border-radius: 5px;

    box-shadow: 2px 2px 8px 0px $gray;

    background-color: $lightGray;

    transition: all .3s ease;

    &.hover {
      box-shadow: none;
      opacity: .9;
    }

    &.active {
      color: $cream;
      box-shadow: none;
      background-color: $green;
    }
  }

  &--auth {
    width: 100%;

    padding: 20px 0;

    border-radius: 0 0 10px 10px;

    outline: none;

    background-color: $lightGray;

    &:hover {
      opacity: .9;
    }

    &.active {
      opacity: .9;
    }
  }

  &--hide {
    width: 30px;
    height: 30px;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    border-color: $gray;
    border-style: solid;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-radius: 0 0 100% 0;

    background-color: $cream;

    &.hover {
      opacity: .9;
    }

    &.active img {
      transform: translate(-35%, -35%) rotate(180deg);
    }

    & img {
      position: absolute;
      top: 35%;
      left: 35%;

      transform: translate(-35%, -35%);

      transition: all .2s ease;
    }
  }

  &--default {
    width: 220px;
    height: 50px;

    margin-left: 15px;
    margin-top: 25px !important;

    @include flex();

    @include font($cream, 18px, bold);

    border-radius: 5px;

    transition: all .2s ease;

    &:hover {
      opacity: .9;
    }

    &:first-child {
      margin-left: 0;
    }

    &--green {
      background: linear-gradient(180deg, #35B537 0%, #088D0A 100%);
    }

    &--yellow {
      background: linear-gradient(180deg, #d5d814 0%, #ff980c 100%);
    }

    &--red {
      background: linear-gradient(180deg, #ff0c0c 0%, #ac0000 100%);
    }
  }
  
  @media screen and (max-width: 1300px) {
    &--default {
       margin-top: 70px !important;
    }
  }

  &--image {
    margin-left: 10px;
    padding: 5px;

    @include flex();

    background-color: transparent;

    transition: all .2s ease;

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      transform: scale(1.05);
    }

    & img {
      height: 22px;
    }
  }

  &--back {
    @include flex(flex-start);

    line-height: .8;

    background-color: transparent;

    transition: all .3s ease;

    &:hover {
      transform: scale(1.02);
    }

    & img {
      margin-right: 15px;
    }
  }

  &--link {
    @include font($green, 18px, 500);

    position: relative;

    &:hover:before {
      width: 100%;
    }
  
    &::before {
      width: 0;
      height: 1px;
  
      content: '';
      position: absolute;
      bottom: -3px;
      left: 0;
  
      background-color: $green;
  
      transition: all .3s ease;
    }
  }

  &--similar {
    border-left: 1px solid $lightGray;
    background-color: transparent;

    transition: all .3s ease;

    &:hover img {
      transform: scale(1.1);
    }

    & img {
      width: 12px;
      height: 12px;
    }
  }
}
