.page {
  &-product {
    &--card {
      display: inline-block !important;
    }
  }

  &-notification {
    &-list {
      padding: 5px 10px;
      border: 1px solid #e6e6e6;
      border-radius: 5px;
      margin: 8px 0;
    }

    &-title {
      font-size: 15px;
      font-weight: 600;
    }

    &-body {
      font-size: 13px;
    }
  }

  &-wrapper {
    width: 100%;
    height: 100vh;

    padding: 0 0 0 20px;

    overflow: hidden;

    transition: all 0.3s ease;

    &.shorter {
      padding-left: 20%;

      @include media("1100px") {
        padding-left: 225px;
      }
    }

    &.footer {
      padding-bottom: 60px;
    }
  }

  &-container {
    width: 100%;
    height: 100%;

    padding: 40px 40px 20px 20px;

    overflow-y: auto;

    position: relative;
  }

  &-footer {
    width: 100%;
    height: 70px;

    @include flex();

    // &::-webkit-scrollbar {
    //   display: none;
    // }

    overflow-x: scroll;

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    box-shadow: 0 0px 4px 2px $lightGray;

    background-color: $cream;
  }

  &-header {
    width: 100%;

    margin-bottom: 70px;

    @include flex(space-between);
  }

  &-title {
    @include font($black, 20px, 700);
  }

  &-btns-container {
    @include flex();

    position: absolute;
    top: 40px;
    right: 40px;
    z-index: 1;

    border-radius: 5px;

    // background-color: $cream;
  }

  &-modal {
    &-wrapper {
      @include flex();

      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9999;

      visibility: hidden;

      opacity: 0;

      background-color: rgba(0, 0, 0, 0.6);

      transition: all 0.3s ease;

      cursor: pointer;

      &.active {
        visibility: visible;

        opacity: 1;
      }
    }

    &-content {
      padding: 90px 120px 20px;

      border-radius: 5px;

      background-color: $cream;

      cursor: pointer;

      position: relative;
    }

    @media screen and (max-width: 1300px) {
      &-content {
        padding: 0px 0px 0px;
      }
    }
  }

  &-preloader {
    @include flex();

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: transparent;

    & img {
      width: 100px;
      height: 100px;

      animation: pulse 1s ease infinite alternate;

      @keyframes pulse {
        0% {
          transform: scale(1);
        }

        100% {
          transform: scale(1.3);
        }
      }
    }
  }
}
