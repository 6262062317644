.notif {

  &-bar {
    width: 20%;
    min-width: 300px;
    max-height: 100%;

    padding: 20px 10px;

    position: fixed;
    top: 0;
    right: 0;
    z-index: 10000;

    overflow-y: scroll;
    overflow-x: hidden;
  }

  &-wrapper {
    width: 100%;

    margin: 10px 0;
    padding: 20px;

    border-radius: 20px;
    border: 2px solid $lightGreen;
    background-color: $white;

    transition: all .15s ease;
    
    cursor: pointer;

    &:hover {
      transform: scale(1.02);
    }

    &--error {
      text-align: center;
      border: 2px solid $red;
    }
  }
}